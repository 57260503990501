#collasible-nav-dropdown::after {
  display: none;
}

#dropdown-autoclose-outside::after {
  display: none;
}

#dropdown-autoclose-outside:active,
#dropdown-autoclose-outside:focus,
#dropdown-autoclose-outside.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#subjectsearchbar:active,
#subjectsearchbar:focus,
#subjectsearchbar.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (max-width: 600px) {
  .CollapsedNavbar {
    margin-top: 30px;
  }
}

.datePicker {
  background-color: #f5f5f5;
  border: 0px;
  width: 100%;
  height: 40px;
}

.textProfileCard {
  word-break: break-all;
  white-space: pre-wrap;
}

.textProfileCard {
  word-break: break-all;
  white-space: pre-wrap;
}

.datePickerPayment {
  background-color: #ffffff;
  padding-block: 7px;
  border: 1px solid #ced4da;
  width: 100%;
  border-radius: 5px;
  color: black;
  padding-inline: 7px;
}

.datePickerTeacher {
  background-color: white;
  border: 0px;
  padding-left: 5%;
  width: 100%;
  margin-top: 3%;
}
.datePickerTeacher:focus {
  outline: none;
}

.until {
  background-color: white;
  border: 0px;
  padding-left: 5%;
  width: 100%;
  margin-top: 0%;
}

.datePickerTeacherDis {
  background-color: #e9ecef;
  border: 0px;
  padding-left: 5%;
  width: 100%;
  margin-top: 3%;
}

.recommendedTeacherName {
  @media (min-width: 1320px) {
    font-size: 700px;
  }
}
@media screen and (max-width: 600px) {
  .teacherMobileView {
    display: none;
  }
  .teacherMobileViewShow {
    display: block;
  }
}

.border-radius-2 div {
  border-radius: 15px;
}

.stop-scrolling {
  overflow: hidden;
  position: fixed;
}

.mContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.center-col {
  flex: 1;
  overflow-y: scroll;
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 67%;
  background-color: white;
  color: black;
  text-align: center;
  display: block;
}

.responsiveFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: black;
  text-align: center;
  display: block;
}

.hideChat {
  display: block;
}

@media screen and (max-width: 766px) {
  .hideChat {
    display: none;
  }
}

.messages {
  display: none;
}

@media screen and (max-width: 766px) {
  .messages {
    display: block;
  }
}

.teacherProfileSubjectList {
  display: flex;
}

.teacherProfileSubmitButtons {
  margin-left: -10px;
}

@media screen and (max-width: 480px) {
  .teacherProfileSubjectList {
    display: block;
  }
  .teacherProfileSubjectListButton {
    margin-top: 10px;
  }
  .teacherProfileSubmitButtons {
    margin-left: 0px;
  }
}

@media screen and (max-width: 430px) {
  .bookLessonIcons {
    width: 50px;
    margin-top: 20px;
  }
}

.iconsDivider {
  z-index: 1;
}

.see {
  border: 0px;
}

.error-text {
  font-size: 50px;
  text-shadow: -2px -2px 0 cyan, 2px 2px 0 magenta;
  text-align: center;
}

.navigation-text {
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  color: blue;
  font-weight: bold;
}

.navigation-text:hover {
  color: rgb(134, 134, 228);
}

.pointer {
  cursor: pointer;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toogle-input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.toogle-input:checked + .slider {
  background-color: #84dcc6;
}

.toogle-input:focus + .slider {
  box-shadow: 0 0 1px #84dcc6;
}

.toogle-input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:focus {
  outline: none;
}

.font-12 {
  font-size: 12px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.bold{
  font-weight: 500;
}

.opacity-50{
  opacity: 0.5;
}

.no-scrollbar::-webkit-scrollbar { 
  display: none;  
}

.error-div{
  display: flex;
  justify-content: center;
  margin-top: 10%;
}
.error-heading{
  font-size: 120px;
  font-weight: 900;
  background: -webkit-linear-gradient(rgb(255, 255, 255), #5D9C8C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.select-none{
  user-select: none;
}

.underline-hover:hover{
  text-decoration: underline;
}

.loading-logo{
  animation-name: logoloader;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  height:80px;
  width:80px;
}

@keyframes logoloader {
  0%   {margin-top: 0px;}
  50%  {margin-top: 40px;}
  100%  {margin-top: 0px;}
}

.landing-video-background{
  background: #EDFAF7;
  min-height: 700px;
  border-radius: 23% 40% 50% 20% / 37% 43% 50% 63%;
  width: 90%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
}

.landing-video{
  max-width: 800px;
  max-height: auto;
}

.onboard-video-heading{
  width: 400px;
  font-size: 70px;
  font-weight: 600;
}

@media screen and (max-width: 1500px) {
  .landing-video {
    max-width: 680px;
  }
}
@media screen and (max-width: 1340px) {
  .landing-video {
    max-width: 580px;
  }
}
@media screen and (max-width: 1200px) {
  .landing-video {
    max-width: 500px;
  }
  .onboard-video-heading{
    font-size: 50px;
  }
}
@media screen and (max-width: 1050px) {
  .landing-video {
    max-width: 400px;
  }
  .landing-video-background{
    border-radius: 23% 40% 50% 20% / 37% 43% 50% 63%;
    width: 100%;
   
  }
}
@media screen and (max-width: 950px) {
  .landing-video {
    max-width: 350px;
  }
}
@media screen and (max-width: 420px) {
  .landing-video {
    max-width: 280px;
  }
}
.notification {
  color: black;
  padding: 5px;
  position: relative;
}

.notification .badge {
  border-radius: 50%;
  background-color: #5D9C8C;
  color: white;
}

.font-weight-700{
  font-weight: 700;
}

.digits{
  font-family: "Times New Roman", Times, serif;
}


.instant-class {
  position: relative;
  background: white;
}


.instant-class:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-37deg, #84dcc6 0%, #5d9c8c 100% );
  transform: translate3d(5px, 5px, 0) scale(1);
  filter: blur(5px);
  opacity: var(0.24);
  transition: opacity 0.3s;
  border-radius: inherit;
}


.instant-class::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}
      
      
